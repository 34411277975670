$ = jQuery;

$(document).ready(function () { 
     if ($('#simulator .wrapper__simulator ').hasClass('simulator_tonnes')) {
     var field_heure_traitement     = $('#simulateur__tonnes .simulator__heures_traitement--select'),
         field_jours_traitement     = $('#simulateur__tonnes .simulator__jours_traitement--select'),
         form                       = $('#simulator .simulator_tonnes form'),
         btn_submit                 = $('#simulator .wrapper__buton-submit input'),
         select_machine             = $('#simulator .tonnes--name-js').text().split(' ')[1],
         resultTonnes               = $('#simulator .result__simulator--tonne-js'),
         resultMachine              = $('#simulator .wrapper__result--machines'),
         currentMachine             = $('#simulator .current_machine-js'),
         machine_row                = $('#simulator .machines--row'),
         result_simulator           = $('#result__simulator');

     result_simulator.removeClass('open');

     // Move wrapper result Machine
     resultMachine.detach().appendTo(currentMachine);
     machine_row.removeClass('d-none');

     // image after on button on click
     $(document).not(btn_submit).on('click', function(){
          btn_submit.on('click', function(){ 
               event.stopPropagation();
               btn_submit.addClass('input__hover').parent().addClass('cta__hover');
          });
          if( btn_submit.hasClass('input__hover')) {
               btn_submit.removeClass('input__hover').parent().removeClass('cta__hover');
          }
     });


     form.on('submit', function(){
          // check if field is not empty
          if (field_heure_traitement.val()) {
               var value_heure_traitement = parseFloat(field_heure_traitement.val().split(' ', 1));
          }
          if (field_jours_traitement.val()) {
               var value_jours_traitement = parseFloat(field_jours_traitement.val().split(' ', 1));
          }

          // Witch machine is select with acf field
          if (select_machine === '75+' ) {
               select_machine = select_machine.split('+', 1);
               select_machine = parseFloat(select_machine);
          }


          ///// Calculs Tonnes /////
          // Nombre de kilos à traiter par jour -> tonnes par an
          var result_kilo__day = value_heure_traitement * select_machine,
              // Nombre de tonnes à traiter par an -> tonnes par an
              result_tonne__year = result_kilo__day * value_jours_traitement* 0.052;

          if (result_tonne__year % 1) {
               result_tonne__year = result_tonne__year.toFixed(1);
          } 

          existCondition = setInterval(function() {  //execute la fonction toute les 1000ms
               if(form.hasClass('sent')) {
                    // Display result text
                    resultTonnes.text(result_tonne__year);
                    result_simulator.addClass('open');

                    $('html, body').animate({
                         scrollTop: result_simulator.offset().top + -20
                     }, 500);
                    clearInterval(existCondition);
               }
          }, 1000); // check every 1000ms
          return true; 
     });
     $(document).on('reset', form, function(e) {
          e.preventDefault();
     });
     };
});